@import "mixins";

/* Variables
   ========================================================================== */
@import "variables";
@import "bootstrap-variables";
@import "fonts";
@import "material-theme";


/* Bootstrap Core
   ========================================================================== */
@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/* FontAwesome
   ========================================================================== */
@import "./node_modules/font-awesome/scss/font-awesome";

/* Bootstrap Overwrites
   ========================================================================== */
@import "bootstrap-overwrites/alerts";
@import "bootstrap-overwrites/mixins";
@import "bootstrap-overwrites/buttons";
@import "bootstrap-overwrites/dropdowns";
@import "bootstrap-overwrites/input-groups";
@import "bootstrap-overwrites/alerts";
@import "bootstrap-overwrites/labels";
@import "bootstrap-overwrites/navbar";
@import "bootstrap-overwrites/navs";
@import "bootstrap-overwrites/panels";
@import "bootstrap-overwrites/responsive-utilities";
@import "bootstrap-overwrites/modals";
@import "bootstrap-overwrites/breadcrumb";
@import "bootstrap-overwrites/scaffolding";
@import "bootstrap-overwrites/timepicker";

/* Core
   ========================================================================== */
@import "core/global";
@import "core/utilities";
@import "core/fontawesome";
@import "core/glyphicons";
@import "core/animation";
@import "core/layouts";
@import "core/madero-style";


/* UI-Components
   ========================================================================== */
@import "ui-components/modals";
@import "ui-components/alignment";
@import "ui-components/font-style";
@import "ui-components/common-header";
@import "ui-components/background-setting";
@import "ui-components/loading-spinner";
@import "ui-components/off-canvas";
@import "ui-components/action-sheet";
@import "ui-components/distribution";
@import "ui-components/app-header";
@import "ui-components/file-picker";
@import "ui-components/empty-state";
@import "ui-components/file-empty-state";
@import "ui-components/grid";
@import "ui-components/lists";
@import "ui-components/responsive-table";
@import "ui-components/nav-sidebar";
@import "ui-components/timeline.scss";
@import "ui-components/file-selector";
@import "ui-components/template-picker";
@import "ui-components/playlist";
@import "ui-components/provider-connected.scss";
@import "ui-components/provider-file-error.scss";
@import "ui-components/material-ui";
@import "ui-components/coloris";

/* Sections/Views
   ========================================================================== */
@import "sections/widgets";
@import "sections/storage-modal";
@import "sections/storage-selector";
@import "sections/alerts-app";
@import "sections/storage-full";
@import "sections/presentations";
@import "sections/editor-layout";
@import "sections/displays";
@import "sections/schedules";
@import "sections/template-editor-layout";
@import "sections/checkout";
@import "sections/billing-app";
@import "sections/apps";
@import "sections/components/financial";
@import "sections/components/image";
@import "sections/components/video";
@import "sections/components/counter";
@import "sections/components/timedate";
@import "sections/components/colors";
@import "sections/components/playlist";
@import "sections/components/data-table";
@import "sections/components/text";
@import "sections/components/calendar";
@import "sections/components/document";
@import "sections/components/social-media-posts";
