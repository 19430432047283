@use '@angular/material' as mat;
@import "variables";
// Checkbox

.mat-mdc-checkbox {
  $palette-accent-700: mat.m2-get-color-from-palette($rise-blue-palette, 700);
  .mdc-checkbox {
    --mdc-checkbox-selected-checkmark-color: #{$palette-accent-700};
    --mdc-checkbox-state-layer-size: 32px;

    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:not(:checked)~.mdc-checkbox__background {
      background-color: $white !important;
    }

    .mdc-checkbox__checkmark {
      padding: 1px;
    }

    .mdc-checkbox__checkmark-path {
      stroke-width: 5px;
    }
  }

  &.appear-enabled {
    .mdc-checkbox {
      --mdc-checkbox-disabled-selected-checkmark-color: #{$palette-accent-700};
      --mdc-checkbox-disabled-selected-icon-color: #{$white};

      .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background,
      .mdc-checkbox__native-control[disabled]:indeterminate~.mdc-checkbox__background,
      .mdc-checkbox__native-control[data-indeterminate=true][disabled]~.mdc-checkbox__background {
        border-color: var(--mdc-checkbox-unselected-icon-color, $gray-600) !important;
      }
    }
  }
}

.mat-mdc-checkbox label,
.mat-mdc-radio-button label,
.madero-style .mat-mdc-checkbox label,
.madero-style .mat-mdc-radio-button label {
  margin-bottom: 0;
  font-weight: normal;
}

.mdc-checkbox__ripple {
  top: 4px !important;
  left: 4px !important;
  right: 4px !important;
  bottom: 4px !important;
  border-radius: 3px !important;
}

// Ensure checkboxes inside table rows have tight spacing
.table .mat-mdc-checkbox {
  margin: -10px -10px -10px -6px;
}

// Dialog

.cdk-overlay-pane.full-screen {
  width: 100vw;
  max-width: 100vw !important;
  height: calc(100vh - 45px);
  max-height: 100vh;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media (max-width: $screen-xs-max) {
    height: 100vh;

    .mat-mdc-dialog-container .mdc-dialog__surface {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.u_remove-radius {
  .mat-mdc-dialog-container .mat-mdc-dialog-surface {
    border-radius: 0;
  }
}

.mat-mdc-dialog-surface {

  // Ensure that the spinner backdrop covers the modal, but not the full page
  .spinner-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

// Expansion Panel

.mat-expansion-panel-body {
  padding: 0 5px 10px !important;
}

.expansion-panel-flush {
  .mat-expansion-panel-body {
    padding: 0 0 5px !important;
  }
}

// Slide toggle

.mat-mdc-slide-toggle {
  margin-bottom: 2px;

  .mdc-switch__icon--off {
    display: none;
  }
}

// Calendar

.mat-calendar-body-cell-content {
  border-radius: 5px !important;
}
.mat-calendar-header {
  padding-top: 0 !important;
}
.mat-calendar-controls {
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}
.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: normal;
}
.mat-calendar-body-label {
  opacity: 0;
  cursor: default;
}
.mat-calendar-body {
  tr[aria-hidden="true"] {
    display: none;
  }
}

// Chips

.mat-mdc-chip-highlighted {
  .mat-mdc-chip-remove {
    &:hover {
      opacity: 1;
    }
    svg {
      fill: $white;
    }
  }
  &.inverted.mat-accent {
    background-color: $white;
    border: 2px solid var(--mdc-chip-elevated-container-color, $rise-blue);
    .mdc-evolution-chip__cell .mdc-evolution-chip__text-label {
      color: var(--mdc-chip-elevated-container-color, $rise-blue);
    }
  }
  &.u_clickable .mdc-evolution-chip__action--presentational {
    cursor: pointer;
  }
}

// Tooltip

.mdc-tooltip .mdc-tooltip__surface {
  --mdc-plain-tooltip-container-color: #{$white};
  --mdc-plain-tooltip-supporting-text-color: #{$black};
  --mdc-plain-tooltip-supporting-text-size: 12px;

  box-shadow: 0px 0px 5px 0px $dark-blue-shadow-500;
  text-align: left;
  max-width: 360px;
  padding: 10px;
  line-height: 1.5;
}

.mdc-tooltip.upgrade-tooltip .mdc-tooltip__surface {
  overflow: visible;
  position: relative;
  box-shadow: none;
  background-color: $rise-blue;
  color: $white;
  font-size: 13px;
  padding-top: 7px;
  padding-bottom: 7px;

  &::before {
    position: absolute;
    content: '';
    display: inline-block;
    background-color: $rise-blue;
    clip-path: polygon(50% 0, 0 50%, 50% 100%);
    left: -6px;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
}

// Menu

.mat-mdc-menu-content {
  .mat-mdc-menu-item {
    min-height: $mat-input-size;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: none !important;
}

.auth-buttons-menu > .mat-mdc-menu-content {
  padding: 0;
}

// Form fields

html {
  --mat-form-field-container-height: 40px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 8px;
  --mat-form-field-filled-with-label-container-padding-top: 8px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}

.mat-mdc-form-field-infix, .mat-mdc-option {
  min-height: $mat-input-size;
}

.mdc-text-field--outlined:has(.mat-mdc-form-field-text-suffix) {
  padding-right: 0;
}

.mat-mdc-form-field-text-suffix {
  position: relative;
  padding: 10px;
  &::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: $gray-100;
    z-index: -1;
    width: 100%;
  }
}

// Bottom sheet

.mat-bottom-sheet-container {
  background-color: $white;
  box-shadow: 0 0 5px $gray-500;
}

// Button toggle

.mat-button-toggle-group-appearance-standard {
  .mat-button-toggle-label-content {
    // Match button toggle height to madero style input height
    line-height: 38px;
  }

  // Filled style
  &.mat-button-toggle-group.button-toggle-fill {
    border: 1px solid $rise-blue;

    .mat-button-toggle {
      streamline-icon {
        line-height: 1.2;
      }

      svg {
        fill: $rise-blue;
      }

      img {
        margin-bottom: 2px;
      }

      + .mat-button-toggle {
        border-left-color: $rise-blue;
      }

      &.mat-button-toggle-checked {
        background-color: $rise-blue;
        svg {
          fill: $white;
        }
      }
    }
  }
}

// Buttons

.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  // Made this a little darker (default is 0.04)
  opacity: 0.1;
}

.mdc-button {
  --mdc-outlined-button-outline-width: 2px;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base,
.mat-mdc-icon-button.mat-mdc-button-base {
  height: $mat-button-size;
  font-weight: bold;
}

.mat-mdc-outlined-button:not(:disabled).mat-primary {
  --mdc-outlined-button-outline-color: #{$rise-green};
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: #{$white};
}

a.mdc-button:hover {
  text-decoration: none;
}

.mdc-button.small {
  height: 32px;
}


[mat-icon-button] {
  &.mat-mdc-icon-button {
    width: $mat-button-size;
    height: $mat-button-size;
    padding: $mat-icon-button-padding;

    &.mat-mdc-button-touch-target {
      height: $mat-button-size;
      width: $mat-button-size;
    }
  }

  &.small-icon-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $mat-small-button-size;
    height: $mat-small-button-size;
    padding: 0px;

    & > *[role=img] {
      width: $mat-small-icon-size;
      height: $mat-small-icon-size;
      font-size: $mat-small-icon-size;

      svg {
         width: $mat-small-icon-size;
         height: $mat-small-icon-size;
         margin-bottom: 1px;
      }
    }

    .mat-mdc-button-touch-target {
       width: $mat-small-button-size;
       height: $mat-small-button-size;
    }
  }

  &.mat-mdc-menu-trigger {
    padding: 0;
    > i {
      padding: $mat-menu-trigger-padding;
    }
  }
}

// Icons

.mat-mdc-button > .mat-icon,
.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon {
  transform: scale(1.375);

  > svg {
    margin-bottom: 1px;
  }
}

.mat-icon.small-icon {
  transform: scale(0.75);
}

// Table

.mat-mdc-table {

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    border-color: $gray-300;
  }
}

// Spinner

.mat-mdc-progress-spinner {
  &.white circle {
    stroke: $white;
  }
}

.mat-mdc-tab-group {
  border: 1px solid $gray-300;
  border-radius: 8px;

  .mat-mdc-tab-body-wrapper {
    flex: 1;
  }

  .mat-mdc-tab-header {
    border-bottom: 1px solid $gray-300;
  }

  .mdc-tab-indicator__content--underline {
    width: 60%;
  }
  .mdc-tab {
    font-weight: 700;
    color: $shadow-600;
    padding-right: 16px;
    padding-left: 16px;
  }

  &.user-tabs {
    .mdc-tab {
      flex-grow: 0 !important;
      padding: 0;
      margin-right: 2rem;
      justify-content: flex-start;
      min-width: unset;
    }
    .mdc-tab__text-label {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
      color: black;
    }
    .mdc-tab-indicator {
      justify-content: flex-start;
    }
    .mdc-tab-indicator__content--underline {
      width: 100%;
    }  
  }
}

.mat-mdc-chip-remove .mat-icon {
  font-size: 14px !important;
  width: 14px !important;
  height: 14px !important;
}

// Mat form field
.mat-mdc-form-field {
  $palette-primary-400: mat.m2-get-color-from-palette($rise-green-palette, 400);
  &.emails-field {
    input {
      line-height: 2;
    }
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
    .mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
    .mat-mdc-standard-chip .mat-mdc-chip-action-label {
      overflow: hidden;
    }
    .mat-mdc-standard-chip {
      height: 24px;
    }
  }
  &.remove-subscript {
    .mat-mdc-form-field-subscript-wrapper {
      &::before {
        content: none;
      }
    }
  }
  .mdc-text-field--focused .mdc-notched-outline {
    &__leading, &__trailing, &__notch {
      border-width: 1px;
    }
  }
  &.url-field, &.date-picker {
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .mat-mdc-form-field-icon-suffix {
      height: $mat-input-size;
      background-color: $palette-primary-400;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 1;

      svg, i {
        color: white;
      }

      i {
        font-size: 14px;
      }
    }
  }

  &.url-field .mat-mdc-form-field-suffix {
    padding: 8px 12px;
  }

  // Email fields are excluded as they need variable height for multiple email chips
  &:not(.emails-field) .mat-mdc-text-field-wrapper {
    height: 40px;
  }

}

.cdk-overlay-container:has(
  .mat-datepicker-popup,
  #confirmForm
) {
  z-index: 10000;
}

// List
mat-nav-list > a.mat-mdc-list-item {
  &:hover span {
    text-decoration: underline;
    color: $rise-blue;
  }
}

// Dialog 
.mat-mdc-dialog-container {
  .mat-mdc-dialog-title {
    font-size: 1.8rem;
    font-weight: 700;
  }

  .mat-mdc-dialog-content {
    --mat-app-body-medium-line-height: 1.5;
    --mdc-dialog-supporting-text-color: $black;
    color: $black;
    line-height: 1.5;
    padding: 8px 20px 15px;
  }

  .mat-mdc-dialog-actions {
    padding: 0 20px 20px;
  }

  h4 {
    margin: 0;
    text-align: center;
  }
}